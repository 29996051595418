if (document.querySelector('.listing__switch')) {
    const categoryButtons = document.querySelectorAll('.listing__switch__button');
    const postsContainer = document.querySelector('.listing__posts')
    const blogLoadMore = document.querySelector('.listing__loadmore')

    categoryButtons.forEach(button => {
        button.addEventListener('click', () => {
            if (!button.classList.contains('listing__switch__button--active')) {
                $.ajax({
                    type: 'post',
                    dataType: 'json',
                    url: blasFilter_globals.ajax_url,
                    data: {
                        cat_slug: button.getAttribute('data-cat-slug'),
                        action: 'blogFilter',
                        _ajax_nonce: blasFilter_globals.nonce
                    },
                    success: function (response) {
                        if ('success' == response.type) {
                            if (response.posts.length > 0) {
                                var newPosts = '';
                                if (response.posts) {

                                    response.posts.forEach(post => {
                                        newPosts = newPosts + `<article class="blog__post aos-init aos-animate" data-aos="fade-up">
                                        <a href="${ post['permalink'] }" class="blog__post-image">
                                            <img loading="lazy"  class="gray" src="${ post['gray_img'] }" alt="${ post['gray_img_alt'] }">
                                            <img loading="lazy"  class="color" src="${ post['color_img'] }" alt="${ post['color_img_alt'] }">
                                        </a>
                                        <a href="${ post['permalink'] }" class="blog__post-content">
                                            <span class="blog__post-date">${ post['date'] }</span>
                                            <h4 class="blog__post-title">${ post['title'] }</h4>
                                        </a>
                                    </article>`;
                                    })
                                    postsContainer.innerHTML = newPosts
                                    postsContainer.setAttribute('data-blog-paged', 0)
                                    categoryButtons.forEach(button => {
                                        if (button.classList.contains('listing__switch__button--active')) {
                                            button.classList.remove('listing__switch__button--active')
                                        }
                                    })
                                    button.classList.add('listing__switch__button--active')
                                    if (blogLoadMore) {
                                        if (response.paged >= response.max - 1) {
                                            blogLoadMore.style.display = 'none';
                                        } else {
                                            blogLoadMore.style.display = 'flex';
                                        }
                                    }
                                }
                            } else {
                                postsContainer.setAttribute('data-blog-paged', 0)
                                categoryButtons.forEach(button => {
                                    if (button.classList.contains('listing__switch__button--active')) {
                                        button.classList.remove('listing__switch__button--active')
                                    }
                                })
                                button.classList.add('listing__switch__button--active')
                                postsContainer.innerHTML = `<span class="listing__noPosts">Nie znaleziono pasujących postów...</span>`;
                                blogLoadMore.style.display = 'none';
                            }
                        }
                    }
                })
            }
        })
    });

    if (document.querySelector('.listing__loadmore')) {

        blogLoadMore.addEventListener('click', () => {
            categoryButtons.forEach(button => {
                if (button.classList.contains('listing__switch__button--active')) {
                    cat = button.getAttribute('data-cat-slug')
                }
            });
            $.ajax({
                type: 'post',
                dataType: 'json',
                url: blasFilter_globals.ajax_url,
                data: {
                    cat_slug: cat,
                    paged: parseInt(postsContainer.getAttribute('data-blog-paged')) + 1,
                    action: 'blogLoadMore',
                    _ajax_nonce: blasFilter_globals.nonce
                },
                success: function (response) {
                    console.log(response)
                    if ('success' == response.type) {
                        var newPosts = '';
                        response.posts.forEach(post => {
                            newPosts = newPosts + `<article class="blog__post aos-init aos-animate" data-aos="fade-up">
                            <a href="${ post['permalink'] }" class="blog__post-image">
                                <img loading="lazy"  class="gray" src="${ post['gray_img'] }" alt="${ post['gray_img_alt'] }">
                                <img loading="lazy"  class="color" src="${ post['color_img'] }" alt="${ post['color_img_alt'] }">
                            </a>
                            <a href="${ post['permalink'] }" class="blog__post-content">
                                <span class="blog__post-date">${ post['date'] }</span>
                                <h4 class="blog__post-title">${ post['title'] }</h4>
                            </a>
                        </article>`;
                        })
                        postsContainer.innerHTML = postsContainer.innerHTML + newPosts;
                        postsContainer.setAttribute('data-blog-paged', response.paged)

                        if (response.paged >= response.max) {
                            blogLoadMore.style.display = 'none';
                        }
                    }
                }
            })
        })
    }
}