AOS.init({
  startEvent: "DOMContentLoaded",
  offset: 50,
  duration: 1000,
  once: true,
});

lightbox.option({
  resizeDuration: 200,
  wrapAround: true,
});

document.addEventListener("DOMContentLoaded", () => {
  const menuHamburger = document.querySelector(".nav__hamburger");
  const mobileNav = document.querySelector(".mobileNav-container");
  menuHamburger.addEventListener("click", () => {
    menuHamburger.querySelectorAll("span").forEach((span) => {
      span.classList.toggle("active");
      span.classList.toggle("not-active");
    });
    mobileNav.classList.toggle("open");
    mobileNav.querySelector(".mobileNav").classList.toggle("open");
  });

  const nav = document.querySelector(".nav");

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      nav.classList.add("nav--moved");
    } else {
      nav.classList.remove("nav--moved");
    }
  }

  // var headerSwiper = new Swiper(".header__slider", {
  //     slidesPerView: 1,
  //     effect: "fade",
  //     loop: true,
  //     autoplay: {
  //         delay: 5000,
  //         disableOnInteraction: true,
  //     },
  //     pagination: {
  //         el: ".header__slider-pagination",
  //     },
  // });
  var opinions = new Swiper(".opinions__slider", {
    slidesPerView: 1,
    navigation: {
      nextEl: ".opinions__slider-next",
      prevEl: ".opinions__slider-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    breakpoints: {
      1150: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
    },
  });
  if(document.querySelector('.productDetails')){

    const items = document.querySelectorAll(".scriptClass");
    const items_max = items.length / 5;
    var i = 1;
    const next = document.querySelector(".productDetails-next");
    const prev = document.querySelector(".productDetails-prev");
  
    next.addEventListener("click", () => {
      if (i + 3 <= items_max) {
        i += 1;
        items.forEach((item) => {
          if (
            parseInt(item.getAttribute("data-id")) <= i + 2 &&
            parseInt(item.getAttribute("data-id")) >= i
          ) {
            item.style.display = "flex";
          } else {
            item.style.display = "none";
          }
        });
      }
    });
  
    prev.addEventListener("click", () => {
      if (i >= 2) {
        i -= 1;
        items.forEach((item) => {
          if (
            parseInt(item.getAttribute("data-id")) <= i + 2 &&
            parseInt(item.getAttribute("data-id")) >= i
          ) {
            item.style.display = "flex";
          } else {
            item.style.display = "none";
          }
        });
      }
    });
  }
  function swiperCard() {
    if (window.innerWidth > 1150) {
      i = 1;
      items.forEach((item) => {
        if (
          parseInt(item.getAttribute("data-id")) <= i + 2 &&
          parseInt(item.getAttribute("data-id")) >= i
        ) {
          item.style.display = "flex";
        } else {
          item.style.display = "none";
        }
      });
    } else {
      items.forEach((item) => {
        item.style.display = "flex";
      });
    }
  }

  if (document.querySelector(".productDetails")) {
    swiperCard();
    window.addEventListener("resize", swiperCard);
  }

  if (document.querySelector(".modal")) {
    var modal = document.getElementById("modal");
    var modalOverlay = document.querySelector("#modal-overlay");
    var closeButton = document.querySelector("#close-button");
    closeButton.addEventListener("click", () => {
      modal.classList.add("closed");
      modalOverlay.classList.add("closed");
    });

    const navSearchButton = document.querySelector(".nav__search-button");

    navSearchButton.addEventListener("click", () => {
      modal.classList.remove("closed");
      modalOverlay.classList.remove("closed");
    });
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  document.documentElement.style.setProperty("--sc", `${scrollbarWidth}px`);

  if (document.querySelector(".distribution")) {
    const input = document.querySelector(".distribution__search__input input");
    const items = document.querySelectorAll(".distribution__search__item");
    input.addEventListener("input", () => {
      console.log(input.value);
      items.forEach((item) => {
        if (
          item
            .getAttribute("data-localization")
            .toLowerCase()
            .includes(input.value.toLowerCase())
        ) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
    });
  }
});
