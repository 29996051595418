const buttons = document.querySelectorAll('.awards__switch-control')
const contents = document.querySelectorAll('.awards__switch-content')
buttons.forEach(button => {
    button.addEventListener('click', () => {
        if (!button.classList.contains('awards__switch-control--active')) {
            buttons.forEach(b => {
                if (b.classList.contains('awards__switch-control--active')) {
                    b.classList.remove('awards__switch-control--active')
                }
            });
            button.classList.add('awards__switch-control--active')
        }
        contents.forEach(c => {
            if(c.classList.contains('awards__switch-content--active')){
                c.classList.remove('awards__switch-content--active')
            }
        });
        contents.forEach(content=>{
            if(content.getAttribute('data-id') == button.getAttribute('data-id') ){
                content.classList.add('awards__switch-content--active')
            }
        })
    })
})